<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%;">
        <div>
          <div class="input-group">
            <div
              v-if="field.copy !== undefined && field.copy"
              v-b-tooltip.top="$t('general.copyToClipboard')"
              class="input-group-prepend cursor-pointer"
              @click="copyText(field.value)"
            >
              <span class="input-group-text">
                <i class="fas fa-copy" />
              </span>
            </div>
            <div v-if="field.prepend" class="input-group-prepend">
              <span class="input-group-text">{{ field.prepend }}</span>
            </div>
            <input
              v-model="value"
              :type="inputType"
              class="form-control"
              :class="validationState"
              :placeholder="field.placeholder ? field.placeholder : ''"
              :disabled="isDisabled"
              @click="showMenu()"
              @input="resetConfigDebugValue"
              @blur="checkRequiredFields"
              @keydown.esc="showConfigValues = false"
              @keydown.tab="showConfigValues = false"
            />
            <div v-if="field.append" class="input-group-append">
              <span class="input-group-text">{{ field.append }}</span>
            </div>
            <div class="input-group-append">
              <span class="input-group-text font-italic text-muted">
                {{ getTypeCastValueType }}
              </span>
            </div>
            <div
              v-if="field.readonly !== undefined && field.readonly"
              class="input-group-append"
            >
              <span class="input-group-text">
                <i class="fas fa-lock"></i>
              </span>
            </div>
            <div
              v-if="
                field.createDataStructure !== undefined &&
                  field.createDataStructure &&
                  !isDisabled
              "
              v-b-tooltip.top="$t('dataStructures.create')"
              class="input-group-append"
              @click="addDataStructure"
            >
              <span class="input-group-text">
                <i class="fal fa-plus icon-lg cursor-pointer" />
              </span>
            </div>
            <div
              v-else-if="showConfigValues && !isDisabled"
              v-b-tooltip.top="$t('config.configCreate')"
              class="input-group-append"
              @click="addConfigValue"
            >
              <span class="input-group-text">
                <i class="fal fa-plus icon-lg cursor-pointer" />
              </span>
            </div>
            <div
              v-else-if="value && !isDisabled"
              class="input-group-append"
              @click="deleteValue"
            >
              <span class="input-group-text">
                <i class="fal fa-circle-xmark icon-lg cursor-pointer" />
              </span>
            </div>
          </div>
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
        <span v-if="configValue" class="form-text text-muted pl-1">
          {{ configValueSyntax(configValue) }}
        </span>
        <span v-else-if="debugValue" class="form-text text-muted pl-1">
          {{ debugValue.syntax }}
        </span>
      </div>
    </div>
    <v-menu
      v-model="showConfigValues"
      content-class="bg-white"
      absolute
      offset-y
      :min-width="width"
      :max-width="width"
      :position-x="x"
      :position-y="y"
      max-height="300"
      @focus="showMenu()"
      @blur="showMenu(false)"
    >
      <v-list-item-group
        v-if="configValuesFiltered && configValuesFiltered.length > 0"
        v-model="selectedConfigValue"
        color="primary"
      >
        <v-subheader>Config Values</v-subheader>
        <v-list-item
          v-for="(item, index) in configValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setConfigValue(item)"
        >
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group
        v-if="outputValuesFiltered && outputValuesFiltered.length > 0"
        color="primary"
      >
        <v-subheader>Output Values</v-subheader>
        <v-list-item
          v-for="(item, index) in outputValuesFiltered"
          :key="index"
          @click="setOutputValue(item)"
        >
          <v-list-item-title>{{ item.value }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group
        v-if="
          debugValuesAllowed &&
            debugValuesFiltered &&
            debugValuesFiltered.length > 0
        "
        color="primary"
      >
        <v-subheader>Debug Values</v-subheader>
        <v-list-item
          v-for="(item, index) in debugValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setDebugValue(item)"
        >
          <v-list-item-title>{{ item.syntax }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-menu>

    <b-modal
      ref="addConfigValueModal"
      body-class="add-config-value"
      hide-footer
      hide-header
    >
      <CreateConfigValue
        :full-width="true"
        :return-to-route="false"
        :type="type"
        :value="value"
        @config-value-create-saved="configValueSaved"
        @config-value-create-cancel="$refs['addConfigValueModal'].hide()"
      />
    </b-modal>

    <b-modal
      ref="addDataStructureModal"
      body-class="add-config-value"
      :dialog-class="
        createDataStructureState === 1 ? 'create-data-structure' : ''
      "
      size="xl"
      hide-header
      hide-footer
    >
      <CreateDataStructure
        v-if="createDataStructureState === 0"
        :configuration="dataStructureConfiguration"
        :show-in-modal="true"
        :json-data="jsonData"
        @show-editor="showDataStructureEditor"
        @cancel="onDataStructureCanceled"
      />
      <Editor
        v-else-if="createDataStructureState === 1"
        :configuration="dataStructureConfiguration"
        :is-modal="true"
        @saved="onDataStructureSaved"
      />
    </b-modal>

    <b-modal
      ref="addTokenModal"
      body-class="add-config-value"
      hide-footer
      hide-header
      size="lg"
    >
      <TokenEdit
        :return-to-route="false"
        @token-saved="tokenSaved"
        @token-create-cancel="$refs['addTokenModal'].hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import Editor from "@/components/Admins/Settings/DataStructures/Editor/Editor";
import CreateDataStructure from "@/components/Admins/Settings/DataStructures/Wizard/Wizard";
import CreateConfigValue from "@/components/Projects/Settings/Config/Create";
import { bus } from "@/main";
import { copyToClipboard, isJson } from "@/components/Tools/helperFunctions";
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import TokenEdit from "@/components/Projects/Settings/WorkflowToken/Edit";

export default {
  components: {
    CreateDataStructure,
    CreateConfigValue,
    Editor,
    TokenEdit
  },
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      configValue: null,
      valueIndex: null,
      debugValue: null,
      showPassword: false,
      showConfigValues: false,
      x: 0,
      y: 0,
      width: 100,
      selectedConfigValue: undefined,
      createDataStructureState: 0,
      dataStructureConfiguration: {
        dataStructureService: new DataStructures()
      },
      jsonData: "",
      cancelAction: false
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    inputType: function() {
      return "text";
    },
    validationState: function() {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (!this.value) {
        return "is-invalid";
      }
      return "is-valid";
    },
    configValuesFlat: function() {
      let configValues = [];
      this.configValues.forEach(configValue => {
        if (configValue.type === "json") {
          Object.entries(configValue.value).forEach(value => {
            configValues.push({
              label: `${configValue.label}.${value[0]}`,
              name: `${configValue.name}.${value[0]}`,
              value: value[1],
              type: "text"
            });
          });
        } else {
          configValues.push(configValue);
        }
      });
      return configValues;
    },
    configValuesFiltered: function() {
      if (
        this.field.configValuesDisabled !== undefined &&
        this.field.configValuesDisabled
      ) {
        return [];
      }

      let values =
        this.configValuesFlat.filter(item => this.checkType(item)) ?? [];
      if (!this.value || typeof this.value !== "string") return values;
      return values.filter(item => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .includes(this.value.toString().toLowerCase()) ||
          this.configValueSyntax(item).includes(this.value)
        );
      });
    },
    outputValuesFiltered: function() {
      if (
        this.field.outputValuesDisabled !== undefined &&
        this.field.outputValuesDisabled
      ) {
        return [];
      }
      if (!this.value || typeof this.value !== "string") {
        return this.outputValues;
      }
      return this.outputValues.filter(item => {
        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          this.outputValueSyntax(item).includes(this.value)
        );
      });
    },
    debugValuesFiltered: function() {
      if (!this.value || typeof this.value !== "string") {
        return this.debugValues;
      }

      return this.debugValues.filter(item => {
        if (typeof item.value !== "string") return true;

        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          item.syntax.includes(this.value)
        );
      });
    },
    debugValuesAllowed: function() {
      return (
        Object.keys(this.field).includes("debugValues") &&
        this.field.debugValues
      );
    },
    type: function() {
      return "text";
    },
    getTypeCastValueType() {
      if (typeof this.value !== "boolean" && Number(this.value)) {
        return "number";
      } else if (
        this.value === "true" ||
        this.value === "false" ||
        typeof this.value === "boolean"
      ) {
        return "bool";
      }
      return "string";
    }
  },
  watch: {
    value: function() {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      if (this.configValue) {
        newValue = this.configValueSyntax(this.configValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);

      if (this.field.name === "batchSize" || this.field.name === "limit") {
        bus.$emit("csvBatchBatchSizeChanged");
      }

      if (this.field.name === "error_handling") {
        bus.$emit("show-hide-critical-path");
      } else if (this.field.name === "functions") {
        if (this.field.options === undefined || this.field.options === null) {
          return;
        }
        const option = this.field.options.find(o => o.value === this.value);
        if (option && option.types) {
          this.node.attrs.data.input[0].value = JSON.stringify(
            option.types,
            null,
            1
          );
        }
      }
    }
  },
  mounted() {
    this.value = this.field.value ?? "";

    if (typeof this.value === "string" && this.value.startsWith("{{config")) {
      const configValue = this.configValuesFlat.find(
        cf => cf.name === this.value.replace("{{config.", "").replace("}}", "")
      );
      if (configValue) {
        this.setConfigValue(configValue);
      }
    } else if (
      typeof this.value === "string" &&
      this.value.startsWith("{{output")
    ) {
      const outputValue = this.outputValues.find(
        dv => dv.value === this.value.replace("{{output.", "").replace("}}", "")
      );
      if (outputValue) {
        this.setOutputValue(outputValue);
      }
    }

    bus.$on("fireActionFinished", this.onFireActionFinished);
    bus.$on("fileAccessTreeSelected", this.onFileAccessTreeSelected);
  },
  destroyed() {
    bus.$off("fireActionFinished", this.onFireActionFinished);
    bus.$off("fileAccessTreeSelected", this.onFileAccessTreeSelected);
  },
  methods: {
    copyText(text) {
      copyToClipboard(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    checkType(item) {
      if (this.type === "number") {
        return !isNaN(item.value);
      }

      return true;
      //this.type === "text" || this.field.type === "time";
    },
    onFireActionFinished() {
      this.cancelAction = false;
    },
    showMenu(state = true, i = null, key = null) {
      if (i !== null && key !== null) {
        this.valueIndex = {
          i: i,
          key: key
        };
      }

      if (!state && this.field.type === "int" && isNaN(this.value)) {
        this.value = "";
      }
      let pos = this.$refs.wrapper?.getBoundingClientRect();
      this.x = pos.x;
      this.y = pos.y + pos.height;
      this.width = pos.width;
      this.showConfigValues = state;
      this.selectedConfigValue = undefined;
    },
    configValueSyntax(configValue) {
      return `{{config.${configValue.name}}}`;
    },
    setConfigValue(configValue) {
      this.configValue = configValue;
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][
          this.valueIndex.key
        ] = this.configValueSyntax(configValue);
        return;
      }
      this.value =
        typeof configValue.value === "object"
          ? JSON.stringify(configValue.value)
          : configValue.value;
      this.debugValue = null;
    },
    outputValueSyntax(outputValue) {
      return `{{output.${outputValue.value}}}`;
    },
    setOutputValue(outputValue) {
      this.configValue = null;
      this.debugValue = null;
      this.$set(this.field, "jsonData", outputValue.data);
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][
          this.valueIndex.key
        ] = this.outputValueSyntax(outputValue);
        return;
      }
      this.value = this.outputValueSyntax(outputValue);
    },
    setDebugValue(debugValue) {
      this.debugValue = debugValue;
      this.$set(this.field, "jsonData", JSON.stringify(debugValue.value));
      if (typeof debugValue.value !== "string") {
        this.value = debugValue.syntax;
        return;
      }

      this.value = debugValue.value;
    },
    selectChange(value) {
      this.value = value;
      if (this.field.onChange !== undefined) {
        bus.$emit(
          "fireAction",
          {
            name: this.field.onChange,
            label: this.field.onChange
          },
          false
        );
      }

      setTimeout(this.checkRequiredFields, 100);
    },
    addValue() {
      let element = {};
      this.field.fields.forEach(field => {
        this.$set(element, field.name, "");
      });
      this.value.push(element);
    },
    removeValue(index) {
      this.value.splice(index, 1);
    },
    deleteValue() {
      this.resetConfigDebugValue();
      this.value = "";
      this.field.value = "";
      this.field.jsonData = null;
    },
    resetConfigDebugValue() {
      this.configValue = null;
      this.debugValue = null;
    },

    addConfigValue() {
      this.$refs["addConfigValueModal"].show();
    },

    configValueSaved(configValue) {
      this.setConfigValue(configValue);
      this.$refs["addConfigValueModal"].hide();
      bus.$emit("update-config-values");
    },
    checkRequiredFields() {
      bus.$emit("checkRequiredFields");
    },
    fireAction(field) {
      this.cancelAction = !this.cancelAction;
      bus.$emit("fireAction", field, !this.cancelAction);
    },

    showConnectionOperator(condition, value, index) {
      return (
        condition.values[index - 1] &&
        condition.values[index - 1]?.type !== "bracketOpen" &&
        value.type !== "bracketClose"
      );
    },

    conditionToString(condition) {
      let string = "";
      condition.values.forEach((c, index) => {
        string += " ";
        if (this.showConnectionOperator(condition, c, index)) {
          let connectionOperator = this.connectionOperators.find(
            co => co.value === c.connection_operator
          ).text;
          string += connectionOperator + " ";
        }
        if (c.type === "condition") {
          let operator = this.operators.find(o => o.name === c.operator);
          string += `${c.left ?? ""} ${operator?.operator ?? ""} ${c.right ??
            ""}`;
        } else if (c.type === "bracketOpen") {
          string += "(";
        } else if (c.type === "bracketClose") {
          string += ")";
        }
      });
      return string;
    },
    operatorSelected(value, operator) {
      if (this.disableSecondField.includes(operator)) {
        value.right = "";
      }
    },
    setValue(object, key, value) {
      this.$set(object, key, value);
      this.reRenderList();
    },
    addDataStructure() {
      if (this.field.jsonData === undefined) {
        this.$toast.fire({
          title: this.$t("workflowDesigner.createDataStructureJsonError", {
            name: this.field.value
          }),
          icon: "warning"
        });
        return;
      }

      this.jsonData = this.field.jsonData;

      if (!isJson(this.jsonData)) {
        this.$toast.fire({
          title: this.$t("workflowDesigner.createDataStructureJsonError", {
            name: this.field.value
          }),
          icon: "warning"
        });
        return;
      }

      this.createDataStructureState = 0;
      this.$refs["addDataStructureModal"].show();
    },
    showDataStructureEditor() {
      this.createDataStructureState = 1;
    },
    onDataStructureSaved(dataStructure) {
      this.$refs["addDataStructureModal"].hide();
      this.deleteValue();

      const dataStructureSelectField = this.node.attrs.data.configuration.find(
        d => d.name === "dataStructureSelect"
      );
      if (dataStructureSelectField) {
        this.$set(dataStructureSelectField, "value", "existing");
      }

      const dataStructureField = this.node.attrs.data.configuration.find(
        d => d.name === "dataStructure"
      );
      if (!dataStructureField) return;

      dataStructureField.options.push({
        value: dataStructure.id,
        label: dataStructure.classname
      });
      this.$set(dataStructureField, "value", dataStructure.id);
    },
    onDataStructureCanceled() {
      this.$refs["addDataStructureModal"].hide();
    },
    showAddToken() {
      this.$refs["addTokenModal"].show();
    },
    tokenSaved(token) {
      this.$refs["addTokenModal"].hide();
      this.field.options.push({
        value: token.id,
        label: token.label
      });
      this.value = token.id;
    },
    onFileAccessTreeSelected(selected) {
      if (selected.isFile && this.field.name === "searchPattern") {
        this.value = "* | *name* | *.txt |";
      }
    }
  }
};
</script>

<style lang="scss">
.modal-body.add-config-value {
  padding: 0;
}

.input-group-append {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}

.v-input--selection-controls__input .v-icon {
  color: #e4ebef;
}
</style>
