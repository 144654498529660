<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("config.configCreate") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-secondary mr-2" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
        <b-button
          v-show="saveButtonEnabled"
          variant="primary"
          @click="storeConfig"
        >
          {{ $t("general.save") }}
        </b-button>
        <b-button v-show="!saveButtonEnabled" variant="secondary">
          {{ $t("general.save") }}
        </b-button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <form ref="form" class="form" @submit="storeConfig">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("config.name") }}
          </label>
          <div :class="inputColClass">
            <b-form-input
              v-model="form.name"
              type="text"
              :placeholder="$t('config.nameEnter')"
              class="form-control"
              :class="!$v.form.name.required ? 'is-invalid' : 'is-valid'"
              required
              @input="validateFields"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required">
              {{ $t("validation.required.technicalName") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("config.label") }}
          </label>
          <div :class="inputColClass">
            <b-form-input
              id="label"
              v-model="form.label"
              :placeholder="$t('config.label')"
              class="form-control"
              :class="!$v.form.label.required ? 'is-invalid' : 'is-valid'"
              required
              @input="validateFields"
            >
            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.label.required">
              {{ $t("validation.required.label") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("config.type") }}
          </label>
          <div :class="inputColClass">
            <v-select
              v-model="$v.form.type.$model"
              class="form-control"
              :class="!$v.form.type.required ? 'is-invalid' : 'is-valid'"
              :items="types"
              :menu-props="{ offsetY: true }"
              @change="typeChanged"
            />
          </div>
        </div>

        <div v-if="form.type === 'json' && typeof form.value === 'object'">
          <b-row>
            <b-col md="12">
              <table class="table table-head-custom" style="min-width: 100%">
                <thead>
                  <tr>
                    <th class="align-middle">{{ $t("config.key") }}</th>
                    <th class="align-middle">{{ $t("config.value") }}</th>
                    <th class="align-middle">
                      <b
                        class="btn btn-icon btn-light btn-sm mr-1"
                        @click="addRow"
                      >
                        <i class="fal fa-plus"></i>
                      </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(entry, key) in form.value"
                    :key="key"
                    class="jsonRows"
                  >
                    <td>
                      <div class="form-group row align-items-center">
                        <div class="col-md-12 col-lg-12 col-xl-12">
                          <b-form-input
                            class="formKeys form-control"
                            :value="key"
                            :placeholder="$t('config.key')"
                            required
                            @change="keyUpdated(key, $event)"
                          >
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="form-group row align-items-center">
                        <div class="col-md-12 col-lg-12 col-xl-12">
                          <b-form-input
                            v-model="form.value[key]"
                            class="formValues form-control"
                            :value="entry"
                            :placeholder="$t('config.value')"
                          >
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b
                        class="btn btn-icon btn-light btn-sm mr-1"
                        @click="deleteRow(key)"
                      >
                        <i class="fal fa-trash"></i>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </div>

        <div v-if="form.type !== 'json'">
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("config.value") }}
            </label>
            <div :class="inputColClass">
              <b-form-input
                v-model="form.value"
                :type="form.type"
                :placeholder="$t('config.value')"
                class="form-control"
                :class="!$v.form.value.required ? 'is-invalid' : 'is-valid'"
                required
                @input="validateFields"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";

import Config from "@/components/Projects/Settings/Config/config";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ProjectsSettingsConfigCreate",
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    returnToRoute: {
      type: Boolean,
      default: true
    },
    returnRoute: {
      type: String,
      default: "projectSettingsConfig"
    },
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String,
      default: ""
    },
    copyFrom: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      saveButtonEnabled: false,
      isBusy: false,
      form: {
        name: "",
        label: "",
        type: "text",
        value: ""
      },
      types: [
        {
          text: "json",
          value: "json"
        },
        {
          text: "text",
          value: "text"
        },
        {
          text: "number",
          value: "number"
        }
      ],
      show: true
    };
  },
  computed: {
    inputColClass: function () {
      if (this.fullWidth) {
        // col-xl-3 col-lg-3
        return "col-xl-9 col-lg-9";
      }
      return "col-md-9 col-lg-4 col-xl-6";
    }
  },
  validations: {
    form: {
      name: { required },
      label: { required },
      type: { required },
      value: { required }
    }
  },
  watch: {
    "form.type": {
      deep: true,

      handler() {
        if (this.form.type !== "json") {
          this.form.value = "";
        }
        this.validateFields();
      }
    }
  },
  mounted() {
    this.form.value = this.value;
    this.form.type = this.type;

    if (this.copyFrom) {
      this.setCopyFromData();
    }

    this.validateFields();
  },
  methods: {
    setCopyFromData() {
      this.form.name = this.copyFrom.name + this.$t("config.copy");
      this.form.label = this.copyFrom.label;
      this.form.type = this.copyFrom.type;
      this.form.value = this.copyFrom.value;
    },
    deleteRow(key) {
      delete this.form.value[key];
      this.$forceUpdate();
    },
    addRow() {
      this.form.value[""] = "";
      this.$forceUpdate();
    },
    keyUpdated(oldKey, newKey) {
      this.form.value[newKey] = this.form.value[oldKey];
      delete this.form.value[oldKey];
      this.$forceUpdate();
    },
    typeChanged() {
      if (this.form.type === "json" && typeof this.form.value !== "object") {
        this.form.value = {};
        this.addRow();
      }
      if (this.form.type !== "json" && typeof this.form.value === "object") {
        this.saveButtonEnabled = false;
        this.form.value = "";
      }
      this.$forceUpdate();
    },
    storeConfig() {
      Config.store(this.form)
        .then(response => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("config.configCreated")
          });
          if (this.returnToRoute) {
            this.$router.push({ name: this.returnRoute });
          }
          this.$emit("config-value-create-saved", response.data.data);
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          $.each(error.response.data.errors, function (fieldName, errors) {
            var fieldElement = $("#" + fieldName);
            fieldElement.addClass("is-invalid");
            fieldElement.next().text(errors[0]);
            fieldElement.on("keyup", function () {
              fieldElement.removeClass("is-invalid");
            });
          });
        });
    },
    cancel() {
      if (this.returnToRoute) {
        this.$router.push({ name: this.returnRoute });
      }
      this.$emit("config-value-create-cancel");
    },
    resetForm() {
      this.form = {
        name: "",
        label: "",
        type: "text",
        value: ""
      };
    },
    validateFields() {
      this.$v.$touch();
      this.saveButtonEnabled = !this.$v.$invalid;
    }
  }
};
</script>
